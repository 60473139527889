<template>
  <div class="basicinfo">
    <div style="text-align: left;"><Head title="手机号解绑"></Head></div>
    <div class="cont">
      <el-form
        ref="form"
        :model="phoneForm"
        :rules="phoneRules"
        class="form_width"
        label-width="180px"
        size="small"
      >
        <el-form-item prop="phone" label="新手机号码：">
          <el-input
            v-model="phoneForm.phone"
            clearable
            placeholder="请输入手机号码"
            type="number"
            max="11"
            class="phone"
            tabindex="1"
            @change="changePhone"
          />
        </el-form-item>
        <el-form-item prop="password" label="密码：">
          <el-input
            v-model="phoneForm.password"
            clearable
            placeholder="请输入密码"
            type="password"
            class="phone"
            tabindex="2"
          />
        </el-form-item>
        <el-form-item prop="captcha" label="短信验证码：">
          <div style="display: flex">
            <el-input
              ref="captcha"
              v-model="phoneForm.captcha"
              clearable
              placeholder="输入手机验证码"
              name="captcha"
              tabindex="3"
            />
            <el-button
              v-bind:disabled="dis"
              type="primary"
              @click="handlegetBtn"
              style="width: 95px; margin-left: 10px"
            >
              <span v-if="show">发送验证码</span>
              <span v-else>重新发送({{ count }}s)</span>
            </el-button>
          </div>
        </el-form-item>
        <div class="btn-wrap">
          <el-button
            :loading="loading"
            type="primary"
            class="submit-btn"
            @click.native.prevent="handleSubmit"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isPhone } from "@/utils/validate.js";
import { sendSMS } from "@/api/login";
import { modify } from "@/api/common";
import md5 from 'js-md5'
import * as base64 from 'js-base64/base64.js'
import Head from './Head'
export default {
  name: "EntUnbinding",
  components: {
    Head
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请填写手机号！"));
      } else if (isPhone(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("密码不能为空！"));
      }else if (value.length < 6) {
        callback(new Error("密码不小于6位数！"));
      } else {
        callback();
      }
    };
    const validateCaptcha = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码！"));
      } else {
        callback();
      }
    };
    return {
      loading: false, // 按钮loading
      dis: true,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //计时器倒计时
      count: "",
      phoneForm: {
        phone: ''
      },
      phoneRules: {
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        captcha: [{ required: true, trigger: "blur", validator: validateCaptcha }],
      },
    };
  },
  computed: {
    ...mapGetters([
      'phone',
    ])
  },
  created() {
    // this.phoneForm.phone = this.phone
  },
  methods: {
    // 手机号输入验证
    changePhone() {
      if(this.phoneForm.phone && !isPhone(this.phoneForm.phone)) {
        this.dis = false
      }
    },
    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('ccc');
          const { phone } = this.phoneForm
          console.log('password:',this.phoneForm.password);
          console.log('md5:',md5(this.phoneForm.password));
          console.log('base64:',base64.encode(md5(this.phoneForm.password)));
          const obj={
            phone,
            password: md5(this.phoneForm.password),
            smsCode: this.phoneForm.captcha
          }
          modify(obj).then(res=>{
            if(res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '解绑成功，请重新登录！',
                onClose: ()=>{
                  /* this.$router.push({ // 登录成功跳转至首页
                    path: '/'
                  }) */
                  this.goOut()
                  setTimeout(() =>{
                    location.reload()
                  },2000) 
                }
              })
              
            }else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      })
    },
    async goOut() {
      await this.$store.dispatch('user/logout').then(res=>{
        console.log(res);
        if(res.status === 200) {
          if(res.data.code === 0) {
            this.$router.push({
              path: '/'
            })
            setTimeout(() =>{
              location.reload()
            },300)
          }
        }
      })
    },
    // 从新发送验证码
    handlegetBtn() {
      if (this.phoneForm.phone && !isPhone(this.phoneForm.phone)) {
        let TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.isGrey = true;
          this.show = false;
          this.dis = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isGrey = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);

          sendSMS({
            phone: this.phoneForm.phone,
            smsType: 2, //1 用户注册 2 更换手机号 3 忘记密码 4 微信小程序绑定手机号
          }).then((res) => {
            if (res.status === 200) {
              // console.log(res.data);
              this.$message({
                type: "success",
                message: res.data.message,
              });
            } else {
              this.$message.error(res.data.message);
            }
            // console.log(res);
          });
        }
      } else {
        this.$message.error("手机号码不能为空！");
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.basicinfo {
  .title {
    text-align: left;
  }
  .cont {
    text-align: left;
    border-top: 1px solid #f5f5f5;
    margin-top: 10px;
    .form_width {
      width: 480px;
      margin-top: 20px;
      /* margin: 80px auto; */

      .btn-wrap {
        margin-left: 180px;
      }
    }
  }
}
</style>